import React from "react"
import styled from "styled-components";
import {Reveal} from "react-reveal"

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

`;

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  margin: 0;
  @media(min-width: 768px){
    padding-bottom: 30px;
    width: 80%;
  }
  
`;
const InfoWrapper = styled.div`
  width: 0;
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  @media(min-width: 768px){
    width: 20%;
  }
 
`;

const StyledHeaderH2 = styled.h2`
  color: ${({theme}) => theme.colors.mainNavyBlue};
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  text-align: left;
  padding: 50px 0 20px;
  position: relative;
  @media(min-width: 768px){
    font-size: 40px;
    padding: 0;
  }
`;

const CaseStudiesSingleHeader = ({data}) => {
  const naglowek = data.naglowek;
    return (
        <ContentWrapper>
            <InfoWrapper/>
            <HeaderWrapper>
                <Reveal effect="fadeInUp">
                  <StyledHeaderH2>{ naglowek }</StyledHeaderH2>
                </Reveal>
            </HeaderWrapper>
        </ContentWrapper>
    );
}

export default CaseStudiesSingleHeader
