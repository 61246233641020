import React from "react"
import styled from "styled-components"

const StyledHeader = styled.h3`
  color: ${({theme}) => theme.colors.mainTextDarkGrey};
  width: 100%;
  font-size: 20px;
  text-align: left;
  margin-bottom: 20px;
  position: relative;
  @media(min-width: 992px) {
    font-size: 26px;
  }
  &.invisible {
    display: none;
  }
}`

const CaseStudiesSingleCheckOtherHeader = ({ data }) => {
  const naglowek = data.naglowek
  return (
      <StyledHeader>{naglowek}</StyledHeader>
  )
}

export default CaseStudiesSingleCheckOtherHeader
