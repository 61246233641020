import React from "react"
import styled from "styled-components"
import {graphql, Link, useStaticQuery} from "gatsby"
import { Reveal } from "react-reveal"
import { LocalizationContext } from "../../layout/layout"
import { urlToPage } from "../common/type-to-url-match/type-to-url-match"

const StyledContentWrapper = styled.div`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
`

const SingleCaseStudy = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none;
  width: 100%;
  position: relative;
  background: none;
  margin-bottom: 30px;
  overflow: hidden;
  &:hover {
    > div.zoomedPicture {
      transform: scale(1.2)
    }
  }
  @media(min-width: 768px){
    flex-direction: row-reverse;
    width: 49%;
  }
`
const ZoomedPictureWrapper = styled.div`
    position: absolute;
    height: 0;
    width: 100%;
    transition: transform .5s;
    background-size: cover;
    background-position: center;
    background-image:url( ${({ bgImg }) => bgImg} );
    @media(min-width: 768px) {
      height: 100%;
    }
`

const StyledImageWrapperRwd = styled.div`
  width: 100%;
  height: 200px;
  display: block;
  background-size: cover;
  background-position: center;
  background-image: url( ${({ bgImg }) => bgImg} );
  
  @media(min-width: 425px){
     height: 250px;
  }
  @media(min-width: 768px){
    width: 100%;
    height: auto;
    background: none;
  }
  @media(min-width: 1200px){
    width: 100%;
    height: auto;
  }
`

const StyledTheRestWrapperRwd = styled.div`
  background-color: ${({ theme }) => theme.colors.mainNavyBlue};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  @media(min-width: 768px){
    width: 100%; 
    background-color: ${({ theme }) => theme.colors.navyBlueOpacity08};
    opacity: 1;
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    padding: 0;
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      position: absolute;
      z-index: 0;
      background-color: rgba(82,45,237,1);
      opacity: .5;
  }
 `

const StyledHeaderWrapperRwd = styled.div`
  width: 100%;
  z-index: 2;
  padding: 20px 50px 0 10px;
  @media(min-width: 768px){
  padding: 50px 30px 0 30px;
  }
`

const StyledHeaderRwd = styled.h2`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.mainTextLightBlue};
  word-wrap: break-word;
  @media(min-width: 768px){
    font-size: 20px;
  }
`

const StyledTextWrapperRwd = styled.div`
  width: 100%;
  padding: 25px 50px 20px 10px;
  word-wrap: break-word;
  z-index: 3;
  @media(min-width: 768px){
    padding: 20px 30px 0 30px;
  }
  @media(min-width: 1200px){
    padding: 70px 30px 20px 30px;
  }
`
const StyledClientP = styled.p`
  font-size: 14px;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.mainTextWhite};
  margin-bottom: 20px; 
`

const CaseStudiesSingleSeeAlso = ({ data, globalData, localize }) => {

  const seeAlsoData = data.caseStudy.map(caseStudy => {
    const wizytowka = caseStudy.archeeCaseStudyWizytowka[0]
    return {
      bgImg: wizytowka ? wizytowka.zdjecieWTle[0].url : "",
      text: caseStudy.title,
      caseStudySlug: caseStudy.slug,
      clientName: wizytowka ? wizytowka.klient[0].klienci[0].nazwaKlienta : "",
      caseSlug: urlToPage(localize.lang)[caseStudy.__typename],
      uri: caseStudy.uri
    }
  })
  const globalDependsOnLang = localize.lang === "pl" ? globalData.pl.nodes || "" :  globalData.en.nodes || ""
  const clientWord = globalDependsOnLang?.[0]?.archeeSlowoKlient || ""
  const langPrefix = localize.lang === "pl" ? "/" : "/" + localize.lang + "/"

  return (
    <div>
      <StyledContentWrapper>
        {seeAlsoData.map((caseStudy, key) => (
          <SingleCaseStudy Link to={langPrefix + caseStudy.uri} bgImg={caseStudy.bgImg} key={key}>
            <ZoomedPictureWrapper className="zoomedPicture" bgImg={caseStudy.bgImg}/>
            <StyledImageWrapperRwd bgImg={caseStudy.bgImg}/>
            <StyledTheRestWrapperRwd>
              <StyledHeaderWrapperRwd>
                <Reveal effect="fadeInUp">
                  <StyledHeaderRwd> {caseStudy.text}
                  </StyledHeaderRwd>
                </Reveal>
              </StyledHeaderWrapperRwd>
              <StyledTextWrapperRwd>
                <Reveal effect="fadeInUp">
                  <StyledClientP> {clientWord}: {caseStudy.clientName} </StyledClientP>
                </Reveal>
              </StyledTextWrapperRwd>
            </StyledTheRestWrapperRwd>
          </SingleCaseStudy>
        ))}
      </StyledContentWrapper>
    </div>
  )
}

const LocalizedCaseStudiesSingleSeeAlso = React.forwardRef((props, ref) => {
  const data = useStaticQuery(graphql`
    query{
      pl: allCraftArcheeOgolneTlumaczeniaGlobalSet(filter: { language: { eq: "pl" } }) {
        nodes {
          archeeSlowoKlient
        }
      }
      en: allCraftArcheeOgolneTlumaczeniaGlobalSet(filter: { language: { eq: "en" } }) {
        nodes {
          archeeSlowoKlient
        }
      }
    }`);

  return (
      <LocalizationContext.Consumer>
        {localize => <CaseStudiesSingleSeeAlso {...props} globalData={data} localize={localize} ref={ref}/>}
      </LocalizationContext.Consumer>
  )
} )

export default LocalizedCaseStudiesSingleSeeAlso;


