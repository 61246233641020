import React from "react"
import styled from "styled-components"
import ArrowSVG from "../../../icons/blue_arrow.svg"
import {graphql, Link, useStaticQuery} from "gatsby"
import { LocalizationContext } from "../../../layout/layout"

const StyledButtonWrapper = styled.div`
  display: flex;
  padding-top: 70px;
`

const StyledArrowWrapper = styled.div`
  transform: rotate(180deg);
  margin-right: 5px;
  margin-bottom: -4px;
`

const StyledButton = styled.button`
  color: ${({ theme }) => theme.colors.mainNavyBlue};
  background-color: transparent;
  font-family: ${({ theme }) => theme.fonts.textFont};
  font-weight: 600;
  font-size: 14px;
  outline: none;
  box-shadow: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
`

const BackButton = (props) => {
  const { href, to, textBtn, localize } = props
  const globalDependsOnLang = localize.lang === "pl" ?( textBtn.pl.nodes?.[0] || "") : (textBtn && textBtn.en.nodes?.[0] || "")

  const text = globalDependsOnLang.archeePrzyciskPowrotu || ""

  return (
    <StyledButtonWrapper>
      <StyledArrowWrapper>
        <ArrowSVG/>
      </StyledArrowWrapper>
      {to && <StyledButton {...props} as={Link}>{text}</StyledButton>}
      {!href && !to && <StyledButton {...props}>{text}</StyledButton>}
    </StyledButtonWrapper>
  )
}

const LocalizedBackButton = React.forwardRef((props, ref) => {
    const data = useStaticQuery(graphql`
        query{
            pl: allCraftArcheePowrotyGlobalSet(filter: { language: { eq: "pl" } }) {
                nodes {
                    archeePrzyciskPowrotu
                }
            }
            en: allCraftArcheePowrotyGlobalSet(filter: { language: { eq: "en" } }) {
                nodes {
                    archeePrzyciskPowrotu
                }
            }
        }`);
    return (
        <LocalizationContext.Consumer>
            {localize => <BackButton {...props} textBtn={data} localize={localize} ref={ref}/>}
        </LocalizationContext.Consumer>
    )
})

export default LocalizedBackButton;
