import React from "react"
import styled from "styled-components"
import { Reveal } from "react-reveal"

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-left: 30px;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 200px;
  background-image: url( ${ ({ bgImg }) => bgImg } ); 
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin: 80px 0;
  @media(min-width: 768px){
    height: 400px;
    width: 80%;
  }
  @media(min-width: 1200px){
    height: 600px;
  }
`;

const CaseStudiesSingleImage = ({ data }) => {
  const urlPhoto = data.zdjecie[0].url
  return (
    <Reveal effect="fadeInUp">
      <StyledContainer>
        <StyledWrapper bgImg={ urlPhoto }/>
      </StyledContainer>
    </Reveal>
  )
}


export default CaseStudiesSingleImage
